import {useAppAlerts} from '../../../utilities/alerts'
import {constants} from '../../../config/constants'
import Swal from 'sweetalert2'
import {instanceOfSpError, ISpError} from '../../../common/api_interfaces/Errors'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../store'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {locationHasBiometricsOn} from '../../../common/core/BusinessLogic'
import {REGISTRATION_STEP} from '../../../modules/registration/registration_constants'
import * as ApiCallService from '../../../services/ApiCallService'
// @ts-ignore
import Cookies from 'js-cookie'
import { setAcceptShareGuestDone, setRegistrationID } from '../../../services/store/registration/RegistrationSlice'
import { clearAuthSelfies } from '../../../services/store/AuthModuleSlice'
import { getGuest, setGuestAuth } from '../../../services/store/guests'


export const useContinueRegistration = (): [
   boolean,
   (clearAuth:boolean) => void
] => {
    const [registrationLoading, setRegistrationLoading] = useState<boolean>(false)
    const guest = useSelector((state: RootState) => state.guest)
   const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
   const SourceFlow = useSelector((state: RootState) => state.Source.flow)
   const source_of_business = useSelector((state: RootState) => state.Source.business_source)
   const {fireErrorAlert} = useAppAlerts()
   const navigateTo = useNavigate()
   const dispatch = useDispatch<AppDispatch>()
   const registrationSessionTracker: any = useSelector(
      (state: RootState) => state.registrationSessionTracker
   )
   const guestSessionID = registrationSessionTracker.guest_id
   const bioIsEnabled = locationHasBiometricsOn(LocSettings)
   const base_domain = LocSettings.base_domain
   const PAYMENTS_ON = LocSettings.store_card_for_registration

   const isGuestFromSameCountry = () => {
        if (SourceFlow === constants.flow.pre_checkin) {
           return LocSettings?.location?.global_country_id === guest?.data?.global_country_id
        } else {
           return true
        }
    }

    const continueRegistration  = (clearAuth:boolean) => {
        if (
            guest.isLoading == false &&
            (guest.auth.isAuthenticated != true || Object.keys(guest.data).length == 0) && clearAuth
         ) {
            navigateTo(base_domain + '/auth')
            return
         }
   
        let redirect_link = ''
        let steps_completed = 0
        if (bioIsEnabled && registrationSessionTracker?.check_list?.identity_forms === false) {
            redirect_link = base_domain + '/register/identity_forms'
        } else if (
            registrationSessionTracker?.check_list?.identity_verified === false ||
            !isGuestFromSameCountry()
        ) {
            redirect_link = base_domain + '/register/identity_verification'
        } else if (PAYMENTS_ON && registrationSessionTracker?.check_list?.add_cof === false) {
            redirect_link = base_domain + '/register/add_pay_method'
        } else if (registrationSessionTracker?.check_list?.cvv === false && registrationSessionTracker?.cvv_stored_token) {
            redirect_link = base_domain + constants.routes.private.card_review
        } else {
            steps_completed = 1
            redirect_link = base_domain + '/register/submission_review'
        }

        // We can assume that the guest has had their phone number verified at this point.
        let registrationSteps: {status: 'COMPLETED' | 'FAILED'; code: REGISTRATION_STEP}[] = [
            {code: REGISTRATION_STEP.PHONE_VERIFICATION, status: 'COMPLETED'},
            {
                code: REGISTRATION_STEP.TERMS,
                status:
                    registrationSessionTracker?.check_list?.terms === true
                    ? 'COMPLETED'
                    : 'FAILED',
            },
            {
                code: REGISTRATION_STEP.ID_VERIFICATION,
                status:
                    registrationSessionTracker?.check_list?.identity_verified === true
                    ? 'COMPLETED'
                    : 'FAILED',
            },
        ]
        if (registrationSessionTracker?.check_list?.identity_forms !== undefined) {
            registrationSteps.push({
                code: REGISTRATION_STEP.BIOMETRICS,
                status: registrationSessionTracker.check_list.identity_forms
                    ? 'COMPLETED'
                    : 'FAILED',
            })
        }
        if (registrationSessionTracker?.check_list?.add_cof !== undefined) {
            registrationSteps.push({
                code: REGISTRATION_STEP.COF,
                status: registrationSessionTracker.check_list.add_cof ? 'COMPLETED' : 'FAILED',
            })
        }
        let data: any = {flow: SourceFlow, registration_steps: registrationSteps}
        data.completed = steps_completed
        if (source_of_business) {
            data.source_of_business = source_of_business
        }
        setRegistrationLoading(true)
        ApiCallService.ApiCall(
            data,
            `guests/${guestSessionID}/registrations`,
            'POST',
            LocSettings
        ).then((registrationResponse: any) => {
            Swal.close()
            const jsonRes: any | ISpError = registrationResponse
            if (instanceOfSpError(jsonRes)) {
                const msg = jsonRes.error.message
                fireErrorAlert(msg).then((result) => navigateTo('/' + base_domain))
            }
            if (guestSessionID === null) console.error('guest id NULL')
            let guestID = guestSessionID as number | string
            if (typeof guestID === 'string') guestID = parseInt(guestID)
            dispatch(getGuest(guestID, LocSettings))
            Cookies.set(constants.cookies_name.guest_id, guestSessionID)
            Cookies.set(
                constants.cookies_name.guest_registration_id,
                registrationResponse.data.id
            )
            dispatch(setRegistrationID(registrationResponse.data.id))
            dispatch(setAcceptShareGuestDone(true))

            if (
                bioIsEnabled &&
                registrationSessionTracker?.check_list?.identity_forms === false &&
                guest?.data?.parent_id != null
            ) {
                dispatch(clearAuthSelfies())
            }
            if(clearAuth)
                dispatch(
                    setGuestAuth({
                    isAuthenticated: false,
                    authMethod: null,
                    biometricsLogSyncId: null,
                    })
                )
            setRegistrationLoading(false)
            navigateTo(redirect_link)
        })
    }

    return [registrationLoading, continueRegistration]
}
