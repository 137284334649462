/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useContext, useEffect, useState} from 'react'
import {AppDispatch, RootState} from '../../store'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {constants} from '../../config/constants'
import {HeaderTitleContext} from '../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import {LoadingPage} from '../../modules/widgets/Loaders/LoadingPage'
import './styles/index.scss'
import {ILogoProps, ReceiptLogo} from './components/ReceiptLogo'
import {IReceiptLocation, ReceiptHeader} from './components/ReceiptHeader'
import {ITicketDetails, ReceiptTicketDetails} from './components/ReceiptTicketDetails'
import {
  IItemizedTab,
  ItemizedTab,
  ITicketItem,
  ItemizedTabBySeat,
  ITicketItemDiscount,
} from './components/ItemizedTab'
import {IReceiptTotals, ReceiptTotals} from './components/ReceiptTotals'
import {IReceiptPayment, ReceiptPayment} from './components/ReceiptPayment'
import {IReceiptFooter} from './components/ReceiptFooter'
import * as ApiCallService from '../../services/ApiCallService'
import {
  getTicket,
  getUrlData,
  setError,
  setUrlData,
  setIsPublicReceipt,
  setTicket,
  setTable,
} from '../../services/store/ticket'
import {setModTicket} from '../../services/store/mod_ticket'
import {getGuestCCToken} from '../../services/store/guests/credit_card_tokens'
import {setFlow, setPaymentSource} from '../../services/SourceSlice'
import {
  useLocationBasedBtnColor,
  useLocationBasedTextBorderColor,
} from '../../utilities/helpers'
import {setPayInitialState} from '../../services/store/ticket/payment'
import {logOutGuest} from '../../services/store/guests'
import {SplitPaymentModal} from './SplitPaymentModal'
import {ShareTicketModal} from './ShareTicketModal'
import {useResetOrder} from '../online_ordering/hooks/order_session'
import { FormattedMessage, useIntl } from 'react-intl'

interface IReceipt {
  logo: ILogoProps
  location_info: IReceiptLocation
  ticket_review: number
  ticket_details: ITicketDetails
  item_tab: IItemizedTab
  totals: IReceiptTotals
  payments: [IReceiptPayment]
  footer: IReceiptFooter
}

export const Receipt: FC = () => {
  const intl = useIntl();
  const DEFAULT_VALUE = 'N/A'
  const dispatch = useDispatch<AppDispatch>()
  const dispatchDirect = useDispatch()
  const {short_code} = useParams()
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const ticket: any = useSelector((state: RootState) => state.ticket)
  const loggedInGuest: any = useSelector((state: RootState) => state.guest)
  const guest_cc_tokens = useSelector((state: RootState) => state.guest_cc_tokens)
  const [currentRating, setRating] = useState(0)
  const [heartClick, setHeartClick] = useState(false)
  const [showPayComponent, setShowPayComponent] = useState(false)
  const [fromAuth, setFromAuth] = useState(false)
  const themeText = LocSettings.appearance === 1 ? 'text-white' : 'text-black'

  const [ShowSplit, setShowSplit] = useState<boolean>(false)
  const [ShowShareTicket, setShowShareTicket] = useState<boolean>(false)
  const location = useLocation()

  let navigateTo = useNavigate()
  const locationState = location.state as any
  const dynamicBtnColor = useLocationBasedBtnColor()
  const confirmExtraCss = useLocationBasedTextBorderColor()

  const {setPageTitle} = useContext(HeaderTitleContext)

  let ticketItems: [ITicketItem]

  // Items Check Functions
  const [CheckedItems, setCheckedItems] = useState<any[]>([])
  const [CheckedItemsTotal, setCheckedItemsTotal] = useState(0)
  const [CheckedItemsTax, setCheckedItemsTax] = useState(0)
  const [CheckedItemsDiscount, setCheckedItemsDiscount] = useState(0)
  const [CheckedItemsRemain, setCheckedItemsRemain] = useState(0)
  const [ToPayAmt, setToPayAmt] = useState(0)
  const resetOrderSession = useResetOrder({resetType: 'Nullify'})
  const base_domain = LocSettings.base_domain

  useEffect(() => {
    setPageTitle(intl.formatMessage({id:constants.staticPageTitle.receipt}));
    dispatch(setPayInitialState())

    return () => {
      resetOrderSession()
      dispatch(setTable({}))
    }
  }, [])

  const [IsSplitItemSel, setIsSplitItemSel] = useState(false)

  const toggleSplitModal = () => {
    if (IsSplitItemSel) {
      setIsSplitItemSel(false)
    } else {
      setShowSplit(!ShowSplit)
    }
  }

  const ToggleShareTicketModal = () => {
    setShowShareTicket(!ShowShareTicket)
  }

  const SetSplitItemModal = () => {
    setIsSplitItemSel(true)
  }

  //setting custom class for error message in modal
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-danger btn-sm fs-5',
    },
    buttonsStyling: false,
    icon: 'error',
    background:
      LocSettings.appearance === 1 //if 1 or light mode set white background
        ? '#fffff'
        : '#151521',
    confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
    width: '29rem',
    heightAuto: false,
  })

  useEffect(() => {
    console.log('ticket.isLoading', ticket.isLoading)

    dispatch(setPaymentSource(''))
    if (
      Object.keys(loggedInGuest.data).length != 0 &&
      Object.keys(guest_cc_tokens.data).length == 0
    ) {
      dispatch(getGuestCCToken(loggedInGuest.data.id, LocSettings, ''))
    }
    //Refresh ticket if the page refresh
    if (short_code != undefined) {
      dispatch(setTicket({}))
      dispatch(setUrlData({}))
      dispatchDirect(setModTicket({}))
      dispatch(setFlow(constants.flow.public_receipt))
    } else if (Object.keys(ticket.data).length != 0 && ticket.isLoading == false) {
      dispatch(getTicket(ticket.data.id, LocSettings))
    }
  }, []) //leaving this array empty to prevent too many re-rendes causes the page to go blank

  useEffect(() => {
    if (Object.keys(ticket.error).length != 0) {
      Swal.fire({
        customClass: {
          confirmButton: 'btn btn-danger btn-sm fs-5',
        },
        icon: 'error',
        background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
        text: intl.formatMessage({id:ticket.error.message}),
        confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
        width: '29rem',
        heightAuto: false,
      })
      dispatch(setError({}))
      navigateTo(base_domain)
    } else if (
      ticket.isLoading === false &&
      Object.keys(ticket.data).length == 0 &&
      Object.keys(ticket.fromUrl).length != 0
    ) {
      dispatch(getTicket(ticket.fromUrl.ticket_id, LocSettings))
      dispatch(setIsPublicReceipt(true))
    } else if (
      ticket.isLoading === false &&
      Object.keys(ticket.data).length == 0 &&
      Object.keys(ticket.fromUrl).length == 0 &&
      short_code != undefined
    ) {
      dispatch(getUrlData(short_code))
      dispatch(setFlow(constants.flow.public_receipt))
    } else if (ticket.isLoading === false && Object.keys(ticket.data).length != 0) {
      if (ticket.data.can_pay == 1 && ticket.data.voided == 0) {
        setShowPayComponent(true)
        if (ticket?.data?._embedded?.payments != undefined) {
          ticket?.data?._embedded?.payments.map((payment: any) => {
            if (payment.status == constants.status.payments.auth) {
              setFromAuth(true)
            }
          })
        }

        if (
          (fromAuth || ticket.data.created_on == constants.global_on.created_on_tb) &&
          (LocSettings.bartab == undefined || LocSettings.bartab == 0)
        ) {
          setShowPayComponent(false)
        }
      } else {
        setShowPayComponent(false)
      }

      if (ticketData.average_score != currentRating && heartClick) {
        var RevData = {
          location_id: LocSettings.location.id,
          guest_id: ticketData!._embedded.guests[0].id,
          ticket_id: ticketData.id,
          payment_id: ticketPayments[0].payment_id,
          score: currentRating,
        }
        ApiCallService.ApiCall(
          {...RevData},
          `payment_guest_review`,
          'POST',
          LocSettings,
          true
        ).then((res: any) => {
          if (res.error) {
            Swal.fire({
              customClass: {
                confirmButton: 'btn btn-danger btn-sm fs-5',
              },
              icon: 'error',
              background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
              text: res.error.message,
              confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
              width: '29rem',
              heightAuto: false,
            })
          } else {
            Swal.fire({
              customClass: {
                confirmButton: 'btn btn-primary btn-sm fs-5',
              },
              icon: 'success',
              background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
              text: intl.formatMessage({id:"receipt.paymentdone.alert.review_submitted"}),
              confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
              width: '29rem',
              heightAuto: false,
            })
            ticketData.average_score = currentRating
          }
          setHeartClick(false)
        })
      }
    }
  }, [ticket.error, ticket.data, ticket.fromUrl, currentRating])

  const CalCulateSplitItems = () => {
    let SelectedItemTotal: number = 0
    let SelectedItemTax: number = 0
    let SelectedItemDiscount: number = 0
    let OtherChaegeTotal: number = 0

    let RemainAmt: number = ticket?.data?.totals?.grand_due ? ticket.data.totals.grand_due : 0
    //let AllItems = ticketItems?.filter((itm) => CheckedItems.includes(itm.id));
    if (CheckedItems && CheckedItems.length > 0) {
      CheckedItems.map((item) => {
        const itemPrice: number = item.price ? item.price : 0
        SelectedItemTotal += itemPrice
        let Qty = item.quantity && item.quantity > 0 ? item.quantity : 1
        SelectedItemTax += item.tax ? item.tax * Qty : 0
        OtherChaegeTotal += item.other_charges ? item.other_charges : 0
        if (item.discounts && item.discounts.length > 0) {
          item.discounts.map((disc: any) => {
            SelectedItemDiscount += disc.value ? disc.value / item.dSeatCnt : 0
          })
        }
      })
    }

    RemainAmt = RemainAmt - SelectedItemTotal - SelectedItemTax - OtherChaegeTotal
    setToPayAmt(SelectedItemTotal + SelectedItemTax + OtherChaegeTotal)
    setCheckedItemsTotal(SelectedItemTotal)
    setCheckedItemsDiscount(SelectedItemDiscount)
    setCheckedItemsTax(SelectedItemTax)
    setCheckedItemsRemain(RemainAmt)
  }
  useEffect(() => {
    if (typeof CalCulateSplitItems === 'function') {
      CalCulateSplitItems()
    }
  }, [CheckedItems])

  if (ticket.isLoading || Object.keys(ticket.data).length == 0) {
    return <LoadingPage />
  }

  const root = document.documentElement
  root.style.setProperty('--kt-primary', LocSettings?.primary_color)
  root.style.setProperty('--kt-primary-active', LocSettings?.primary_color)
  root.style.setProperty('--kt-text-primary', LocSettings?.primary_color)
  const GLOBAL_ON_ID = process.env.REACT_APP_GLOBAL_ON
  const ticketData = ticket.data as any
  const empData = ticketData?._embedded?.employee as any
  const [dateOpenAt, timeOpenAt] = ticketData.opened_at_local
    ? ticketData.opened_at_local.split(' ')
    : ['date', 'time']
  ticketItems = ticketData!._embedded.items as [ITicketItem]
  const ticketTotals = ticketData!.totals as IReceiptTotals
  const ticketPayments = ticketData!._embedded.payments as [IReceiptPayment]
  const ticketLocation = ticketData!._embedded.location_info
  const ticketOrderType = ticketData!._embedded.order_type
  const ticketServiceCharges = ticketData!._embedded?.service_charges

  const receiptProps: IReceipt = {
    logo: {
      img_props: {
        logoFilePath: LocSettings.location.logo ?? null,
        global_on_id: GLOBAL_ON_ID,
      },
      location_name: LocSettings.location.location_name,
    },
    location_info: {
      name: ticketLocation.name,
      address: ticketLocation.address,
      address2: ticketLocation.address2,
      state_name: ticketLocation.state_name,
      phone: ticketLocation.phone,
      city: ticketLocation.city,
      zip: ticketLocation.zip,
    },
    ticket_review: ticketData.average_score,
    ticket_details: {
      emp_name: `${empData.first_name ? empData.first_name : ''}`,
      location_name: ticketLocation.name,
      ticket_number: ticketData.ticket_number,
      opened_date: dateOpenAt,
      opened_time: timeOpenAt,
      voided: ticketData.voided,
      created_on: ticketData.created_on,
      order_type: ticketOrderType.name,
      customer_name: ticketData.name
    },
    item_tab: {
      items: ticketItems,
    },
    totals: {
      due: ticketTotals.due,
      sub_total: ticketTotals.sub_total,
      tax: ticketTotals.tax,
      tips: ticketTotals.tips,
      service_charges: ticketTotals.service_charges,
      surcharges: ticketTotals.surcharges,
      total: ticketTotals.total,
      other_charges: ticketTotals.other_charges,
      service_charges_list: ticketServiceCharges,
      inclusive_tax: ticketTotals?.inclusive_tax,
    },
    payments: ticketPayments,
    footer: {
      promo: ticketLocation.promo_area,
      client_footer: ticketLocation.footer,
      socials: {
        facebook: ticketLocation.facebook,
        instagram: ticketLocation.instagram,
        twitter: ticketLocation.twitter,
      },
    },
  }

  const handleGoToTicketDetails = () => {
    if (IsSplitItemSel) {
      if (CheckedItems.length <= 0) {
        Swal.fire({
          customClass: {
            confirmButton: 'btn btn-danger btn-sm fs-5',
          },
          icon: 'error',
          background: LocSettings.appearance === 1 ? '#fffff' : '#151521',
          text: intl.formatMessage({id:"receipt.select_items_you_want_to_pay_for"}),
          confirmButtonText: intl.formatMessage({id:'alert.ok_got_it'}),
          width: '29rem',
          heightAuto: false,
        })
        return false
      }
      //setModTicket
      let AllSeats: any = []
      let AllItems = CheckedItems?.map(function (obj) {
        let Price: number = obj.price ? obj.price : 0
        let Qty = obj.quantity && obj.quantity > 0 ? obj.quantity : 1
        let tax = obj.tax ? obj.tax * Qty : 0
        if (!AllSeats.includes(obj.seat)) {
          AllSeats.push(obj.seat)
        }
        return {id: obj.id, amount: Math.round(Price + tax)}
      })
      dispatchDirect(
        setModTicket({
          SplitOpt: 3,
          CheckedItems: CheckedItems,
          CheckedItemsTotal: CheckedItemsTotal,
          CheckedItemsTax: CheckedItemsTax,
          CheckedItemsDiscount: CheckedItemsDiscount,
          CheckedItemsRemain: CheckedItemsRemain,
          ToPayAmt: Math.round(ToPayAmt),
          ItemsObj: AllItems,
          AllSeats: AllSeats,
        })
      )
    }

    if (
      Object.keys(loggedInGuest.data).length != 0 &&
      loggedInGuest.auth.isAuthenticated == true &&
      (Number(ticket.data.guest_id) == 0 || ticket.data.guest_id == loggedInGuest.data.id)
    ) {
      navigateTo(`${base_domain}/payment-receipt`)
    } else if (
      (ticket?.data?.guest_id != null && ticket.data.guest_id > 0) ||
      ticket.data.created_on == constants.global_on.dispenser_kiosk
    ) {
      dispatch(logOutGuest())
      dispatch(setPaymentSource('GoToPayment'))
      navigateTo(`${base_domain}/auth`)
    } else {
      Swal.fire({
        icon: 'warning',
        title: intl.formatMessage({id:"receipt.login_or_proceed_as_guest"}),
        background:
          LocSettings.appearance === 1 //if 1 or light mode set white background
            ? '#fffff'
            : '#151521',
        buttonsStyling: false,
        customClass: {
          confirmButton: `btn btn-primary ${themeText} btn-sm fs-5`,
          cancelButton: 'btn btn-danger btn-sm fs-5',
          denyButton: 'btn btn-danger btn-sm fs-5',
        },
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id:"common.login"}),
        showDenyButton: true,
        denyButtonText: intl.formatMessage({id:"common.proceed_as_guest"}),
        width: '29rem',
        heightAuto: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(setPaymentSource('GoToPayment'))
          navigateTo(`${base_domain}/auth`)
        } else if (result.isDenied) {
          navigateTo(`${base_domain}/payment-receipt`)
        }
      })
    }
  }

  let SignatureImage = ''
  if (ticket.data.open == 0) {
    let payments = ticket?.data?._embedded?.payments
    let last_payment = payments[payments.length - 1]
    const API_URL = process.env.REACT_APP_SP_API_URL
    if (last_payment?.signature_url) {
      SignatureImage = `${API_URL}/download-file?path=${last_payment?.signature_url}&global_on_id=${GLOBAL_ON_ID}&user_id=${LocSettings.location.owner}`
    }
  }

  const handleSeatChkChange = (e: any, seat: number, SeatItems: any) => {
    if (e.target.checked) {
      console.log('seat', seat)
      SeatItems.map((item: any) => {
        let SeatCnt = item.seat?.split(',').length
        let seats_paid = item.seats_paid?.length > 0 ? item.seats_paid : []
        if (!seats_paid.includes(seat)) {
          let dSeatCnt = SeatCnt && SeatCnt > 0 ? SeatCnt : 1
          const priceSent = item.price ? item.price / dSeatCnt : 0
          const taxAmt = item.tax ? item.tax / dSeatCnt : 0
          let OtherCharge = receiptProps.totals.other_charges
            ? receiptProps.totals.other_charges / dSeatCnt
            : 0
          let OtherChargeByitm = OtherCharge ? OtherCharge / SeatItems.length : 0
          setCheckedItems((prevState) => [
            ...prevState,
            {
              id: item.id,
              seat: seat,
              price: priceSent,
              quantity: item.quantity,
              tax: taxAmt,
              dSeatCnt: dSeatCnt,
              discounts: item.discounts,
              other_charges: OtherChargeByitm,
            },
          ])
        }
      })
    } else {
      SeatItems.map((item: any) => {
        setCheckedItems((current) =>
          current.filter((mid) => {
            return mid.id == item.id && mid.seat == seat ? false : true
          })
        )
      })
    }
  }

  console.log('CheckedItems', CheckedItems)

  const handleChkChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    seat: any,
    id: number,
    price: number | 0,
    quantity: number | null,
    taxAmt: number | 0,
    dSeatCnt: number | 1,
    discounts: [ITicketItemDiscount],
    OtherCharge: number
  ) => {
    if (e.target.checked) {
      setCheckedItems((prevState) => [
        ...prevState,
        {
          id: id,
          seat: seat,
          price: price,
          quantity: quantity,
          tax: taxAmt,
          dSeatCnt: dSeatCnt,
          discounts: discounts,
          other_charges: OtherCharge,
        },
      ])
    } else {
      setCheckedItems((current) =>
        current.filter((mid) => {
          return mid.id == id && mid.seat == seat ? false : true
        })
      )
    }
  }

  let IsQrConfig = false
  let IsQrIntConfig = false
  let QrCodeProduct = LocSettings?.location?.location_products?.filter(
    (locp: any) => locp.global_product_id == constants.products.qr_code.global_product_id
  )[0]

  if (QrCodeProduct != undefined && QrCodeProduct.status == 1) {
    IsQrConfig = true
  }

  let inteGrationStatus = LocSettings?.qr_integrations?.filter(
    (locp: any) =>
      locp.global_integration_id == constants.integrations.qr_code.global_integration_id
  )[0]

  if (inteGrationStatus != undefined && inteGrationStatus.global_integration_id > 0) {
    IsQrIntConfig = true
  }

  let surcharge_item_ids: any = []
  let IsNonSeatPayment = false
  if (receiptProps?.payments?.length > 0) {
    receiptProps.payments.map(function (r) {
      if (r.donation_item_id) {
        surcharge_item_ids.push(r.donation_item_id)
      }
      if (r.surcharge_item_id) {
        surcharge_item_ids.push(r.surcharge_item_id)
      }
      if (r.seat && r.seat != '') {
      } else {
        IsNonSeatPayment = true
      }
    })
  }

  const showSplitButton = () => {
    return (
      LocSettings.split == 1 &&
      ticket.data.created_on != constants.global_on.created_on_tb &&
      fromAuth != true
    )
  }

  return (
    <>
      <table className='container-fluid mw-500px px-0 mt-n5'>
        <tbody>
          {locationState?.isFromOlo && (
            <div className='pb-5 text-center d-flex flex-column'>
                <div
                  ref={(el) =>
                      el?.style.setProperty(
                        'border-color',
                        'rgb(80, 205, 137, 0.3)',
                        'important'
                      )
                  }
                  className='swal2-icon swal2-success'
                >
                  <span
                      ref={(el) =>
                        el?.style.setProperty(
                            'background-color',
                            '#50cd89',
                            'important'
                        )
                      }
                      className='swal2-success-line-tip'
                  ></span>
                  <span
                      ref={(el) =>
                        el?.style.setProperty(
                            'background-color',
                            '#50cd89',
                            'important'
                        )
                      }
                      className='swal2-success-line-long'
                  ></span>
                </div>
            </div>
          )}
          {!locationState?.isFromOlo && (
            <ReceiptLogo
              location_name={receiptProps.logo.location_name}
              img_props={receiptProps.logo.img_props}
            />
          )}
          <tr className='card  mx-20px mx-md-0'>
            <td align='left'>
              <div className='p-5'>
                <ReceiptHeader
                  displayType={LocSettings.location.receipt_contact_info_opt}
                  props={receiptProps.location_info}
                />

                {/* <div className="separator separator-dashed my-3"></div>
                                <div className='text-center text-dark' style={{ fontSize: '17px', textAlign: 'center' }}>
                                    <div><strong>How was your experience? </strong></div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <HeartRating setHeartClick={setHeartClick} setRating={setRating} currentRating={currentRating} ticket_review={receiptProps.ticket_review} />
                                    </div>
                                </div> */}

                <div className='separator separator-dashed my-3'></div>

                <div className='w-100'>
                  <ReceiptTicketDetails props={receiptProps.ticket_details} items={receiptProps.item_tab.items} />
                </div>

                <div className='separator separator-dashed my-3'></div>
                {IsSplitItemSel ? (
                  <ItemizedTabBySeat
                    surcharge_item_ids={surcharge_item_ids}
                    handleSeatChkChange={handleSeatChkChange}
                    handleChkChange={handleChkChange}
                    CheckedItems={CheckedItems}
                    IsSplitItemSel={IsSplitItemSel}
                    other_charges={receiptProps.totals.other_charges}
                    items={receiptProps.item_tab.items}
                  />
                ) : (
                  <ItemizedTab
                    surcharge_item_ids={surcharge_item_ids}
                    items={receiptProps.item_tab.items}
                  />
                )}

                <ReceiptTotals props={receiptProps.totals} />

                <div className='separator separator-dashed my-3'></div>

                <div className='pb-0 text-center text-break' id='payment_section'>
                  <ReceiptPayment props={receiptProps.payments} />
                </div>

                {receiptProps.payments.length ? (
                  <div className='separator separator-dashed my-3'></div>
                ) : (
                  ''
                )}

                <div>
                  <table border={0} cellPadding='5' cellSpacing='0' width='100%'>
                    <tbody>
                      {IsSplitItemSel ? (
                        <>
                          <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '>                            <FormattedMessage id='receipt.selected' defaultMessage='Selected'/>
</td>
                            <td />
                            <td />
                            <td className='text-end text-gray-400 fw-bold fs-6'>
                              ${CheckedItemsTotal ? (CheckedItemsTotal / 100).toFixed(2) : '0.00'}
                            </td>
                          </tr>
                          <tr style={{fontWeight: '700', fontSize: '14px'}} className='text-dark'>
                            <td colSpan={4} className='text-start '>
                            <FormattedMessage id='receipt.prorated_across_check' defaultMessage='Prorated Across Check'/>
                            </td>
                          </tr>
                          <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '>
                            <FormattedMessage id='receipt.discounts' defaultMessage='Discounts'/>
                            </td>
                            <td />
                            <td />
                            <td className='text-end text-gray-400 fw-bold fs-6'>
                              $
                              {CheckedItemsDiscount
                                ? (CheckedItemsDiscount / 100).toFixed(2)
                                : '0.00'}
                            </td>
                          </tr>
                          <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.tax' defaultMessage='Tax'/></td>
                            <td />
                            <td />
                            <td className='text-end text-gray-400 fw-bold fs-6'>
                              ${CheckedItemsTax ? (CheckedItemsTax / 100).toFixed(2) : '0.00'}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={4}>
                              <div className='separator separator-dashed my-1'></div>
                            </td>
                          </tr>
                          <tr>
                            <td className='text-start text-gray-700 fw-semibold fs-6 '><FormattedMessage id='profile.receipt.you_pay' defaultMessage='You Pay'/></td>
                            <td />
                            <td />
                            <td className='text-end text-gray-400 fw-bold fs-6'>
                              ${ToPayAmt > 0 ? (ToPayAmt / 100).toFixed(2) : '0.00'}
                            </td>
                          </tr>
                          <tr style={{fontWeight: '700', fontSize: '17px'}} className='text-dark'>
                            <td className='text-start'><FormattedMessage id='profile.receipt.balance_due' defaultMessage='Balance Due' /></td>
                            <td />
                            <td />
                            <td className='text-end'>
                              $
                              {CheckedItemsRemain > 0
                                ? (CheckedItemsRemain / 100).toFixed(2)
                                : '0.00'}
                            </td>
                          </tr>
                        </>
                      ) : (
                        <>
                          {(ticket.data.totals.due / 100.0).toFixed(2) === '0.00' || 0 ? (
                            <></>
                          ) : (
                            <tr style={{fontWeight: '700', fontSize: '17px'}} className='text-dark'>
                              <td className='text-start'><FormattedMessage id='profile.receipt.balance_due' defaultMessage='Balance Due' /></td>
                              <td />
                              <td />
                              <td className='text-end' id='grand_total'>
                                ${(ticket.data.totals.grand_due / 100.0).toFixed(2)}
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>

                {showPayComponent && !locationState?.isFromOlo && (
                  <div className='pt-5'>
                    <div className='card row'>
                      <div className='w-100 text-center'>
                        {showSplitButton() ? (
                          <button
                            type='button'
                            onClick={toggleSplitModal}
                            style={{background: 'none', width: '38%'}}
                            className={`btn btn-outline btn-outline-dark btn-active-light-dark`}
                          >
                            <span className={`indicator-label`}>
                              {IsSplitItemSel ? intl.formatMessage({id:"receipt.close"}) : intl.formatMessage({id:"receipt.split"})}
                            </span>
                          </button>
                        ) : (
                          ''
                        )}
                        <button
                          type='button'
                          onClick={handleGoToTicketDetails}
                          style={
                            showSplitButton() ? {width: '57%', marginLeft: '10px'} : {width: '100%'}
                          }
                          className={`btn btn-primary btn-outline ${
                            IsSplitItemSel && CheckedItems.length == 0 ? 'opacity05' : ''
                          } ${confirmExtraCss}`}
                        >
                          <span className={`indicator-label ${dynamicBtnColor}`}>
                            {ticket.data.created_on == constants.global_on.created_on_tb ||
                            fromAuth == true
                              ? intl.formatMessage({id:"receipt.close_my_tab"})
                              : intl.formatMessage({id:"receipt.pay_my_ticket"})}
                          </span>
                        </button>
                        {/* <CustomButton 
                                                title={ticket.data.created_on == constants.global_on.created_on_tb || fromAuth==true ? 'Close My Tab' : 'Pay My Ticket'}
                                                onClick={handleGoToTicketDetails}
                                                extraClass='w-75'
                                                /> */}
                      </div>
                    </div>
                  </div>
                )}
                {SignatureImage != '' && !locationState?.isFromOlo ? (
                  <div className='card-body  p-0'>
                    <div className=' mx-20px mx-md-0'>
                      <div className='logo'>
                        <img
                          src={SignatureImage}
                          className='h-100px w-100 symbol'
                          onError={({currentTarget}) => {
                            currentTarget.onerror = null // prevents looping
                            currentTarget.alt = ''
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {IsQrConfig &&
                IsQrIntConfig &&
                ticket.data.open != 0 &&
                ticket.data.created_on != constants.global_on.created_on_tb &&
                fromAuth != true ? (
                  <>
                    <div className='separator separator-dashed my-3'></div>
                    <div className='pb-0 break-text text-center' id='footer_section'>
                      <label onClick={ToggleShareTicketModal} className='text-center'>
                      <FormattedMessage id='receipt.share_ticket' defaultMessage='Share Ticket' />
                      </label>
                    </div>
                  </>
                ) : (
                  ''
                )}
                {/* <ReceiptFooter props={receiptProps.footer}/> */}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {ShowSplit ? (
        <SplitPaymentModal
          IsNonSeatPayment={IsNonSeatPayment}
          handleGoToTicketDetails={handleGoToTicketDetails}
          toggleModal={toggleSplitModal}
          SetSplitItemModal={SetSplitItemModal}
          items={ticketItems}
          HeaderData={receiptProps.ticket_details}
          ticketDetails={ticket.data}
        />
      ) : (
        ''
      )}
      {ShowShareTicket ? (
        <ShareTicketModal ticketId={ticketData.id} toggleModal={ToggleShareTicketModal} />
      ) : (
        ''
      )}
    </>
  )
}
