import { IAPILocSettings } from '../../../services/ApiCallService'
import {itemObject} from '../../../services/store/ordering/menu/categories'
import {IModifierGroup, ItemInfoState} from '../state/CartState'
import * as ApiCallService from '../../../services/ApiCallService'
import moment from 'moment'
import {IModifiers} from '../state/ModGroupState'

export interface ILocalModifier extends IModifiers {
  global_modifier_type_id: number
  required?: number
  minimum?: number
  maximum?: number
  option?: number
  livemenu?: number
  modGroupId?: number
  modifiers?: IModifiers[]
}

export const generateNumericUid = () => {
  const timestamp = Date.now().toString() // Convert current timestamp to a string

  const random = Math.floor(Math.random() * 10000).toString() // Generate a random number between 0 and 9999 and convert to a string

  const uniqueId = timestamp + random // Concatenate the timestamp and random number

  return parseInt(uniqueId).toString().slice(-9) // Convert the resulting string to a numeric value, then to a string, and extract the first 9 characters
}

export const getMenuItemName = (item: itemObject | ItemInfoState | IModifierGroup) => {
  if (item.name_info != null && item.name_info != '') {
    return item.name_info
  }
  if (item.name != null && item.name != '') {
    return item.name
  }

  return ''
}
 
export const getMenuItemIncludedMods = (itemId: number, LocSettings: any, callback: (includedMods: IModifierGroup[]) => void) => {
  ApiCallService.ApiCall({}, `menu/item/${itemId}`, 'GET', LocSettings)
  .then((res: any) => {
    if(res?.error?.message != undefined) {
      callback([])
    } else {
      let itemdetails = res.data?.item

      if (itemdetails) {
        const temp: IModifierGroup[] = []

        itemdetails.modgroups.forEach((modgroup: IModifierGroup) => {
          modgroup.modifiers &&
            modgroup.modifiers.forEach((modifier: ILocalModifier) => {
              if (modifier.default_status) {
                const modGroupIdAdded: ILocalModifier = {
                  ...modifier,
                  modGroupId: modgroup.id,
                }
                if (!temp.some((obj) => obj.id === modGroupIdAdded.id)) {
                  temp.push(modGroupIdAdded)
                }
              }
            })
        })
        
        callback(temp)
      }
    }
  }).catch(() => {
    callback([])
  });
}

export function isStoreOnline(LocSettings: IAPILocSettings, callback: (isOnline: boolean) => void) { 
  ApiCallService.ApiCall({}, `/mobile/settings`, 'GET', LocSettings)
  .then((res: any) => {
    callback(res?.data?.olo?.allow_ordering);
  })
  .catch((err) => {
    callback(false);
  });
}

interface iTimeSlot {
  LocSettings:IAPILocSettings,
  current_date:string,
  isToday:boolean
}

export function GetAvailableTimeSlote(iTimeSlot: iTimeSlot, callback: (slots: string[]) => void) { 
  ApiCallService.ApiCall({current_datetime:iTimeSlot.current_date,isToday:iTimeSlot.isToday?1:0}, `/mobile/get-avialable-ticket-slots`, 'GET', iTimeSlot.LocSettings)
  .then((res: any) => {
    callback(res?.data);
  })
  .catch((err) => {
    callback([]);
  });
}

export const checkInTime = (startTime:String|null, endTime:String|null) => {
  if(!startTime || !endTime || startTime == endTime)  return true
  //console.log(startTime,'=>',moment.utc().format('HH:mm'),'=>',endTime)
  function timeToMinutes(time:String) {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }
  const currentTime = moment().format('HH:mm')
  const minutesStartTime = timeToMinutes(startTime);
  const minutesEndTime = timeToMinutes(endTime);
  const minutesCurrentTime = timeToMinutes(currentTime);
  if(minutesStartTime>minutesEndTime){
    return minutesCurrentTime >= minutesStartTime || minutesCurrentTime <= minutesEndTime; 
  }else{
    return minutesCurrentTime >= minutesStartTime && minutesCurrentTime <= minutesEndTime; 
  }
} 

export const textLimit = (text:string, limit:number = 55, append:string = '...') => {
  if (text.length <= limit) {
    return text;
  }

  return `${text.substring(0, limit)}${append}`
}