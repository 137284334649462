import {FormattedMessage, useIntl} from 'react-intl'
import {constants} from '../../../config/constants'
import {iPaymentData} from '../../../services/store/ticket/payment'

export const ReceiptPaymentData = ({props}: {props: iPaymentData}) => {
   return (
      <>
         <div className='d-flex justify-content-between fw-semibold  mx-1'>
            <div>{props?.card_type}</div>
            <div className='mx-1'>{`$${(props?.amount / 100.0).toFixed(2)}`}</div>
         </div>
         {props?.currency_iso && 
            <div className='d-flex justify-content-between text-gray-700 mx-1'>
               <div>Currency:</div>
               <div className='mx-1'>{props.currency_iso}</div>
            </div>
         }
         <div className='d-flex justify-content-between text-gray-700 mx-1'>
            <div>Last 4:</div>
            <div className='mx-1'>{props?.last4}</div>
         </div>
         <div className='d-flex justify-content-between text-gray-700 mx-1'>
            <div>Trans#:</div>
            <div className='mx-1'>{props?.transaction_no}</div>
         </div>
         <div className='d-flex justify-content-between text-gray-700 mx-1'>
            <div>Auth Code:</div>
            <div className='mx-1'>{props?.auth_code}</div>
         </div>
         <div className='d-flex justify-content-between text-gray-700 mx-1'>
            <div>Reference:</div>
            <div className='mx-1'>{props?.reference_id}</div>
         </div>
         <div className='d-flex justify-content-between text-gray-700 mx-1'>
            <div>Result:</div>
            <div className='mx-1'>{props?.result_message}</div>
         </div>
      </>
   )
}
