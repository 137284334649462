import { FormattedMessage, useIntl } from "react-intl";
import {constants} from "../../../config/constants";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";

export interface IReceiptPayment{
    id:number,
    tender_type_name:string,
    transaction_no:string,
    last4:string,
    amount:number,
    global_tender_name:string,
    payment_id:number,
    donation_item_id:number,
    surcharge_item_id:number,
    seat:string
}

export const ReceiptPayment = ({props}: { props:[IReceiptPayment] }) => {
    const LocSettings = useSelector((state: RootState) => state.LocSettings.value);
    const intl = useIntl();
    const defaultCard = '../media/svg/card-logos/other-card.svg'
    const cardMapper = {
        'Visa': '../media/svg/card-logos/visa.svg',
        'American Express': '../media/svg/card-logos/american-express.svg',
        'Mastercard': '../media/svg/card-logos/mastercard.svg'
    } as any

    return(
        <>
            {
                props.map((payment:any,index)=>{
                    const cardIconSrc = (payment.global_tender_name && cardMapper.hasOwnProperty(payment.global_tender_name)) ?
                        cardMapper[payment.global_tender_name] : defaultCard

                    let status_label = '';
                    if(payment.status == constants.status.payments.declined) {
                        return null; //EA Oct 12 2023: Angel asked to hid the declines on Payment receipt.
                    } else if(payment.status == constants.status.payments.auth) {
                        status_label = `<span class="badge badge-light-warning fs-7 fw-bold">Authorized</span>`;
                    }                    
                    let tenderLast4 = payment.tender_type_name.replace(`#${payment.transaction_no}`, '');
                    return(
                        <>
                            <div className="d-flex justify-content-between mx-1">
                                <div>
                                    {`${tenderLast4 ?? `[${intl.formatMessage({id:"receipt.receiptpayment.tender"})}]`}`} <span dangerouslySetInnerHTML={{__html: status_label}}></span>
                                </div>
                                <div className='mx-1'>
                                    {`$${(payment.original_total_amount/100.00).toFixed(2)}`}
                                </div>
                            </div>
                            {
                                payment.tip>0?
                                    <div className="d-flex justify-content-between mx-1 mt-1">
                                        <div>
                                            <span className='mx-5'><FormattedMessage id='profile.receipt.tip' defaultMessage='Tip'/></span>
                                        </div>
                                        <div className='mx-1'>
                                            {`$${(payment.tip/100.00).toFixed(2)}`}
                                        </div>
                                    </div>:''
                            }

                            {
                                payment.transaction_no 
                                &&
                                <div className="d-flex justify-content-between mx-1 mt-1">
                                    <div>
                                    <FormattedMessage id='receipt.receiptpayment.trans_no' defaultMessage='Trans #'/>{`${payment.transaction_no ?? ''}`}
                                    </div>
                                    <div className='mx-1'>
                                        {
                                            (payment.status === constants.status.payments.auth && payment?.approved_amount != null)
                                            &&
                                            <>{`(${intl.formatMessage({id:"receipt.receiptpayment.authorized_for"})} ${(payment.approved_amount/100).toFixed(2)})`}</>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                payment.tip>0?
                                    <div className="d-flex justify-content-between mx-1 mt-1">
                                        <div>
                                            <span className='mx-5'><FormattedMessage id='profile.receipt.tip' defaultMessage='Tip'/></span>
                                        </div>
                                        <div className='mx-1'>
                                            {`$${(payment.tip/100.00).toFixed(2)}`}
                                        </div>
                                    </div>:''
                            }

                            {
                                payment?._embedded?.transactions != undefined && payment._embedded.transactions.length > 0
                                &&
                                (
                                    payment._embedded.transactions.map((transaction:any) => (
                                        <>
                                            <div className="d-flex justify-content-between mx-1 mt-1">
                                                <div>
                                                    <span className='mx-5'>{transaction.display_name}</span>
                                                </div>
                                                <div className='mx-1'>
                                                    {`$${(transaction.base_amount/100.00).toFixed(2)}`}
                                                </div>
                                            </div>
                                        </>
                                    ))
                                )
                            }
                            {
                                payment.surcharge>0?
                                <div className="d-flex justify-content-between mx-1 mt-1">
                                    <div>
                                        <span className='mx-5'>
                                            {
                                                (LocSettings?.location?.service_charges_name != undefined && LocSettings?.location?.service_charges_name != '') ?
                                                    LocSettings?.location?.service_charges_name
                                                    :
                                                    <FormattedMessage id='profile.receipt.surcharge' defaultMessage='Surcharge' />
                                            }
                                        </span>
                                    </div>
                                    <div className='mx-1'>
                                        {`$${(payment.surcharge/100.00).toFixed(2)}`}
                                    </div>
                                </div>:''
                            }
                            {
                                payment.donation>0?
                                <div className="d-flex justify-content-between mx-1 mt-1">
                                    <div>
                                        <span className='mx-5'><FormattedMessage id='profile.receipt.donation' defaultMessage='Donations' /></span>
                                    </div>
                                    <div className='mx-1'>
                                        {`$${(payment.donation/100.00).toFixed(2)}`}
                                    </div>
                                </div>:''
                            }
                            {
                                index < (props.length-1) &&
                                <>
                                    <div className="d-flex justify-content-between mx-1">
                                        <div className="separator separator-dashed"></div>
                                    </div>
                                </>
                            }
                        </>
                    )
                })
            }
       </>
    )
}
