import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface IRegistrationCheckList {
    accept_share_guest:boolean,
    terms:boolean,
    identity_forms?:boolean,
    identity_verified:boolean,
    add_cof?:boolean,
    cvv:boolean,
}

export interface ICountry {
    id: number,
    code: string,
    [key: string]: any
}

export interface IState {
    id: number,
    name: string,
    [key: string]: any
}

export interface IOCRData {
    first_name: string,
    last_name: string,
    birth_of_date: string,
    country?: ICountry,
    state?: IState,
    document_expiration?: string,
    [key: string]: any
}

export interface IPdf417Data {
    first_name: string,
    last_name: string,
    birth_of_date: string,
    country?: ICountry,
    state?: IState,
    document_expiration?: string,
    [key: string]: any
}

export interface IStoredToken {
    token_id: number,
    last4: string,
    card_type: string,
    cc_token: string
}

export interface IBiometrics {
    selfie: string,
    front_id: undefined|{
        image_name: string,
        id_form_type: string,
    },
    back_id: undefined|{
        image: string,
        id_form_type: string,
    },
    skip_biometrics_enroll: boolean,
    skip_biometrics_enroll_reason_id: number,
    [key: string]: any
}

export interface IRegistrationSession {
    check_list:IRegistrationCheckList|undefined
    guest_id:number|string|null
    redirect_guest_id:number|null,
    registration_id:number|null,
    ocr_data?: IOCRData,
    pdf417_data?: IPdf417Data,
    skip_ocr_pdf417_errors?: boolean,
    skip_ocr_pdf417_validation?: boolean,
    biometrics?: IBiometrics,
    openIdBiometricsView?: boolean,
    twin_registration:{
        is_twin:boolean,
        candidate_guest_ids:number[]
    },
    lastLoggedGuestId?: number|string|null,
    cvv_stored_token: IStoredToken|null
}

const initialState: IRegistrationSession = {
    guest_id: null,
    check_list:undefined,
    redirect_guest_id:null,
    registration_id:null,
    ocr_data: undefined,
    pdf417_data: undefined,
    skip_ocr_pdf417_errors: false,
    skip_ocr_pdf417_validation: false,
    biometrics: undefined,
    openIdBiometricsView: false,
    twin_registration:{
        is_twin:false,
        candidate_guest_ids:[]
    },
    lastLoggedGuestId: undefined,
    cvv_stored_token: null
}

export const registrationSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {
        initLocationCheckList: (state:IRegistrationSession, action:PayloadAction<IRegistrationCheckList>) => {
            state.check_list = action.payload
        },
        setAcceptTermsDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.terms !== undefined) {
                state.check_list.terms = action.payload
            }
        },
        setAcceptShareGuestDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.accept_share_guest !== undefined) {
                state.check_list.accept_share_guest = action.payload
            }
        },
        setIdentityFormsDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.identity_forms !== undefined) {
                state.check_list.identity_forms = action.payload
            }
        },
        setVerifiedIdentityDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.identity_verified !== undefined) {
                state.check_list.identity_verified = action.payload
            }
        },
        setAddedCOFDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.add_cof !== undefined) {
                state.check_list.add_cof = action.payload
            }
        },
        setCvvDone: (state:IRegistrationSession, action:PayloadAction<boolean>) => {
            if(state.check_list !== undefined && state.check_list.cvv !== undefined) {
                state.check_list.cvv = action.payload
            }
        },
        setStoredToken: (state:IRegistrationSession, action:PayloadAction<IStoredToken>) => {
            state.cvv_stored_token = action.payload
        },
        setOCRData: (state, action:PayloadAction<IOCRData|undefined>) => {
            state.ocr_data = action.payload
        },
        setPDF417Data: (state, action:PayloadAction<IPdf417Data>) => {
            state.pdf417_data = action.payload
        },
        setBiometricsData: (state, action:PayloadAction<IBiometrics>) => {
            state.biometrics = action.payload
        },
        clearBiometricsData: (state) => {
            state.biometrics = undefined
        },
        setBiometricsIdView: (state, action:PayloadAction<boolean>) => {
            state.openIdBiometricsView = action.payload
        },
        clearOCRTempData: (state) => {
            state.ocr_data = undefined
            state.pdf417_data = undefined
            state.skip_ocr_pdf417_errors = false
        },
        clearRegistrationChecklist: (state) => {
            state.check_list = undefined
            state.guest_id = null
            state.redirect_guest_id = null
            state.registration_id = null
            state.ocr_data = undefined
            state.pdf417_data = undefined
            state.skip_ocr_pdf417_errors = false
            state.skip_ocr_pdf417_validation = false
            state.biometrics = undefined
            state.openIdBiometricsView = false
            state.cvv_stored_token = null
        },
        setSkipOcrPdf417Errors: (state, action:PayloadAction<boolean>) => {
            state.skip_ocr_pdf417_errors = action.payload
        },
        setSkipOcrPdf417Validations: (state, action:PayloadAction<boolean>) => {
            state.skip_ocr_pdf417_validation = action.payload
        },
        setSessionGuestId: (state:IRegistrationSession, action:PayloadAction<number|string|null>) => {
            state.guest_id = action.payload
            state.lastLoggedGuestId = action.payload
        },
        setRedirectGuestId: (state:IRegistrationSession, action:PayloadAction<number|null>) => {
            state.redirect_guest_id = action.payload
        },
        setRegistrationID: (state:IRegistrationSession, action:PayloadAction<number|null>) => {
            state.registration_id = action.payload
        },
        setTwinCandidates:(state, action:PayloadAction<number[]>) => {
            state.twin_registration.is_twin = true
            state.twin_registration.candidate_guest_ids = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setAcceptTermsDone, setIdentityFormsDone,
    setVerifiedIdentityDone, setAddedCOFDone,
    clearRegistrationChecklist, setSessionGuestId,
    initLocationCheckList, setRedirectGuestId,
    setRegistrationID, setAcceptShareGuestDone,
    setOCRData, setPDF417Data, setBiometricsData,
    setBiometricsIdView, clearOCRTempData,
    setSkipOcrPdf417Errors, clearBiometricsData,
    setTwinCandidates, setSkipOcrPdf417Validations,
    setCvvDone, setStoredToken
} = registrationSlice.actions

