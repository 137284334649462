import {FormattedMessage} from 'react-intl'
import '../styles/idmestyles.scss'

export const HorizontalPromptRuler = ({prompt}: {prompt: string}) => {
   return (
      <div className='d-flex align-items-center'>
         <div className='border-bottom border-gray-300 mw-50 w-100' />
         <span className='fw-bold text-gray-400 fs-7 mx-2'>{prompt}</span>
         <div className='border-bottom border-gray-300 mw-50 w-100' />
      </div>
   )
}

export const VerificationIntro = ({integrationAdvert = ''}: {integrationAdvert: string}) => {
   return (
      <div className='fw-bold fs-6 form-label'>
         <p className='mb-5'>
            {' '}
            <FormattedMessage
               id='registration.verification.we_have_to_verify_identity'
               defaultMessage='We have to verify your identity before we can activate your account. Please enter your contact information below'
            />{' '}
            {integrationAdvert}
         </p>
      </div>
   )
}

export interface IIdmeBtn {
   onIdMeClick: () => void
   appearanceMode: number
}

export const IdMeBtn = ({props}: {props: IIdmeBtn}) => {
   const inlightMode = props.appearanceMode === 0

   return (
      <>
         <a id='id_me_btn_skin' className='d-flex' onClick={props.onIdMeClick}>
            {
               <>
                  <IdMeCheckMark lightMode={inlightMode} />
                  <IdMeText lightMode={inlightMode} />
               </>
            }
         </a>
         <button
            //will need this button to redirect to ID me login
            onClick={props.onIdMeClick}
            type='button'
            id='idmeBUtton'
            className='btn btn-lg w-100 mb-5 d-none'
         />
      </>
   )
}

const IdMeCheckMark = ({lightMode}: {lightMode: boolean}) => {
   const checkMarkStyles = {
      marginLeft: '3px',
      height: 45,
   }

   return (
      <>
         <div style={{width: '10%'}} className='my-auto'>
            {lightMode ? (
               <img src='/media/misc/idverfiy_checkmark.png' style={checkMarkStyles} />
            ) : (
               <img src='/media/misc/idverfiy_checkmark_dark.png' style={checkMarkStyles} />
            )}
         </div>
      </>
   )
}
const IdMeText = ({lightMode}: {lightMode: boolean}) => {
   const textStyles = {
      marginTop: '3px',
      height: 45,
   }

   return (
      <>
         <div style={{width: '90%'}} className='my-auto'>
            {lightMode ? (
               <img
                  src='/media/misc/idverify_text.png'
                  style={textStyles}
                  className='mx-auto d-block'
               />
            ) : (
               <img
                  src='/media/misc/idverify_text_dark.png'
                  style={textStyles}
                  className='mx-auto d-block'
               />
            )}
         </div>
      </>
   )
}
