import {useContext, useEffect, useRef, useState, MouseEvent} from 'react'
import '../css/ProductStyle.scss'
import {HeroImage} from '../components/HeroImage'
import 'tiny-slider/dist/tiny-slider.css'
import TinySlider from 'tiny-slider-react'
import {MenuItemCard} from '../components/MenuItemCard'
import {MenuCategoryHeader} from '../components/MenuCategoryHeader'
import {AppDispatch, RootState} from '../../../store'
import {useDispatch, useSelector} from 'react-redux'
import {setActiveCat} from '../../../services/store/ordering/menu/categories/activeMenuCategorieSlice'
import {HeaderTitleContext} from '../../../../_spc/layout/components/HeaderTitleContext/HeaderTitleContextStore'
import {useNavigate} from 'react-router-dom'
import {capitalizeWords, renderDisclaimer} from '../../../utilities/helpers'
import {setCartLocationId} from '../../../services/store/ordering/cart/cartSlice'
import {sliderSettings} from '../state/StaticConst'
import {LoadingPage} from '../../../modules/widgets/Loaders/LoadingPage'
import {itemObject, menuCategory} from '../../../services/store/ordering/menu/categories'
import {getMenuItemName,checkInTime} from '../common/utilities'
import {FormattedMessage, useIntl} from 'react-intl'
import {useAppAlerts} from '../../../utilities/alerts'
import moment from 'moment'
import { SkeletonLoader } from './SkeletonLoader'

interface Section {
  data: string
}

interface HandleClickScrollEvent extends MouseEvent<HTMLAnchorElement> {
  target: HTMLElement
}

export const MainMenuView = () => {
  const intl = useIntl()
  const LocSettings = useSelector((state: RootState) => state.LocSettings.value)
  const disclaimer = useSelector((state: RootState) => state.LocSettings.value.olo?.disclaimer)
  const selectedCategorySectionId: Section = useSelector(
    (state: RootState) => state.selectedCategorySectionId
  )
  const [activeMenu, setActiveMenu] = useState<string | null>(
    Object.keys(selectedCategorySectionId.data).length ? selectedCategorySectionId.data : ''
  )
  const {setPageTitle} = useContext(HeaderTitleContext)
  const scrollViewRef = useRef<string | null>(null)
  const [verboseDisclaimer, setVerboseDisclaimer] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [filteredData, setFilteredData] = useState<menuCategory[]>([])
  const [scrollCatLeft, setScrollCatLeft] = useState<boolean>(false)
  const [scrollCatRight, setScrollCatRight] = useState<boolean>(false)

  const {fireErrorAlert} = useAppAlerts()

  const dispatch = useDispatch<AppDispatch>()
  const sliderDisplayItemCount = 20
  let navigateTo = useNavigate()
  let base_domain = LocSettings.base_domain
  if ((base_domain && base_domain.trim() === '') || base_domain == null) {
    base_domain = `/${LocSettings.location.id}`
  }

  let menuCategoriesData = useSelector((state: RootState) => state.menuCategories.data)
    .filter(category => !category._embedded.categories?.length && category._embedded.items.length && checkInTime(category.start_time, category.end_time))
  const menuCategoriesIsloading = useSelector((state: RootState) => state.menuCategories.isLoading)
  const menuCategoriesLocationId = useSelector(
    (state: RootState) => state.menuCategories.locationSettingsId
  )

  const dayName = moment().locale("en").format('dddd')


  if (
      !(
          Object.keys(menuCategoriesData).length == 0 && menuCategoriesLocationId &&
          menuCategoriesLocationId != LocSettings.id &&
          menuCategoriesIsloading == false
      )
  ) {
    menuCategoriesData = menuCategoriesData.filter((menu) => {
      return (menu.available_dow && menu.available_dow.indexOf(dayName) > -1) || Object.is(menu.available_dow, null)
    })

    // Now filtered at the main menuCategoriesData
    // menuCategoriesData = menuCategoriesData?.filter((menu) => {
    //   return checkInTime(menu.start_time, menu.end_time)
    // })
  }

  useEffect(() => {
    const element = document.getElementById(activeMenu ?? '')
    if (element) {
      window.scroll({
        top: element.offsetTop - 115,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    //This is to set the LOCATION ID in cart Slice
    dispatch(setCartLocationId(LocSettings.id))
    applyItemNameWordBreak();
  }, [])

  const applyItemNameWordBreak = () => {
    const elements = document.querySelectorAll<HTMLElement>('.item-name');
    elements.forEach((element) => {
        const maxHeight = parseFloat(window.getComputedStyle(element).maxHeight);
        
        element.style.maxHeight = 'none';
        const contentHeight = element.scrollHeight;
        element.style.maxHeight = `${maxHeight}px`;

        if (contentHeight > maxHeight) {
            element.classList.add('break-all');
        } else {
            element.classList.remove('break-all');
        }
    });
  };

  const handleClickScroll = (e: HandleClickScrollEvent) => {
    const idName = e.target.getAttribute('data-id')
    const catName = e.target.getAttribute('data-catname')
    const element = document.getElementById(idName ?? '')
    const stickyNav = document.getElementById('stickyNav')

    console.log(e.target, idName)
    console.log(element)

    if (element) {
      if (stickyNav) {
        stickyNav.scrollLeft = e.target?.offsetLeft - 5
      }
      setTimeout(function () {
        window.scroll({
          top: element.offsetTop - 115,
          behavior: 'smooth',
        })
        // element.scrollIntoView({ behavior: 'smooth', inline: "start" })
      }, 100)
      scrollViewRef.current = 'handleclick'
      setActiveMenu(idName)
      dispatch(setActiveCat(catName))
    }
  }

  const handleScrollEventListener = () => {
    const stickyNav = document.getElementById('stickyNav')
    handleCatScrollArrows()
    const catHeader = document.querySelectorAll('.section-start')
    catHeader.forEach((items, index) => {
      const item = (items as HTMLElement) || null

      const navItem = document.querySelector(`#nav-item-${index}`) as HTMLInputElement
      if (index == 0) {
        if (window.scrollY < item.offsetTop + item.offsetHeight - 114) {
          if (scrollViewRef.current != 'handleclick') {
            setActiveMenu(item.getAttribute('id'))
            if (stickyNav) {
              stickyNav.scrollLeft = navItem?.offsetLeft - 5
            }
          }
        }
      } else if (
        window.scrollY >= item.offsetTop - 116 &&
        window.scrollY <= item.offsetTop + item.offsetHeight - 115
      ) {
        if (scrollViewRef.current != 'handleclick') {
          if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
            // you're at the bottom of the page
            setActiveMenu(catHeader[catHeader.length - 1].getAttribute('id'))
          } else {
            setActiveMenu(item.getAttribute('id'))
            if (stickyNav) {
              stickyNav.scrollLeft = navItem?.offsetLeft
            }
          }
        }
      }
    })
  }

  const handleEventObj = () => {
    return (scrollViewRef.current = '')
  }

  useEffect(() => {
    if (
      Object.keys(menuCategoriesData).length == 0 && menuCategoriesLocationId &&
      menuCategoriesLocationId != LocSettings.id &&
      menuCategoriesIsloading == false
    ) {
      fireErrorAlert('Category Not Available')
      navigateTo(`${base_domain}`)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousewheel', handleEventObj)
    window.addEventListener('touchmove', handleEventObj)
    window.addEventListener('scroll', handleScrollEventListener)
    // cleanup eventlisteners while unmounting
    return () => {
      window.removeEventListener('mousewheel', handleEventObj)
      window.removeEventListener('touchmove', handleEventObj)
      window.removeEventListener('scroll', handleScrollEventListener)
    }
  }, [])

  useEffect(() => {
    setPageTitle(LocSettings.location.location_name)
  }, [])

  useEffect(() => {
    if (
        !(
            Object.keys(menuCategoriesData).length == 0 && menuCategoriesLocationId &&
            menuCategoriesLocationId != LocSettings.id &&
            menuCategoriesIsloading == false
        )
    ) {
      const filteredDataSearch = menuCategoriesData.filter((menu) => {
        return menu._embedded.items.some((item) => {
          const itemName = getMenuItemName(item)
          // if (itemName.toLowerCase().match(searchText.toLowerCase())) {
          //   console.log('itemName', itemName.toLowerCase().match(searchText.toLowerCase()))
          // }
          return itemName.toLowerCase().match(searchText.toLowerCase())
        })
      })
      // console.log('filteredData', filteredDataSearch)

      setFilteredData(filteredDataSearch)
    }
  }, [searchText])

  useEffect(() => {
    if (menuCategoriesIsloading == false && searchText.trim().length == 0) {
      setFilteredData(menuCategoriesData)
    }
  }, [menuCategoriesData])

  useEffect(() => {
    if (Object.keys(menuCategoriesData).length != 0) {
      handleCatScrollArrows()
    }
  }, [menuCategoriesData])

  const handleCatScrollArrows = () => {
    const stickyNav = document.getElementById('stickyNav')

    if (stickyNav) {
      if (stickyNav?.clientWidth < stickyNav?.scrollWidth) {
        if (
          stickyNav.scrollLeft !== 0 &&
          stickyNav.scrollLeft + stickyNav.clientWidth !== stickyNav.scrollWidth
        ) {
          setScrollCatLeft(true)
          setScrollCatRight(true)
        } else if (
          stickyNav.scrollLeft === 0 &&
          stickyNav.scrollLeft + stickyNav.clientWidth !== stickyNav.scrollWidth
        ) {
          setScrollCatLeft(false)
          setScrollCatRight(true)
        } else if (
          stickyNav.scrollLeft !== 0 &&
          stickyNav.scrollLeft + stickyNav.clientWidth === stickyNav.scrollWidth
        ) {
          setScrollCatRight(false)
          setScrollCatLeft(true)
        }
      } else {
        setScrollCatLeft(false)
        setScrollCatRight(false)
      }
    }
  }
  const handleSchrollLeft = (event: MouseEvent) => {
    const stickyNav = document.getElementById('stickyNav')
    if (stickyNav) {
      stickyNav.scrollBy({
        left: -200,
        behavior: 'smooth',
      })
      event.preventDefault()
    }
    setTimeout(() => {
      handleCatScrollArrows()
    }, 200)
  }
  const handleSchrollRight = () => {
    const stickyNav = document.getElementById('stickyNav')
    console.log('width', stickyNav?.clientWidth)
    console.log('scrollWidth', stickyNav?.scrollWidth)

    if (stickyNav) {
      // stickyNav.scrollLeft += 300
      stickyNav.scrollBy({
        left: 200,
        behavior: 'smooth',
      })
    }
    setTimeout(() => {
      handleCatScrollArrows()
    }, 200)
  }

  if (menuCategoriesLocationId == LocSettings.id && (Object.keys(filteredData).length != 0 || searchText.length > 0)) {    
    return (
      <div
        style={{marginBottom: '30px', paddingBottom: '30px'}}
        className='container-fluid max-width-menu-page pt-lg-5 mx-auto'
      >
        <HeroImage />
        {/* navbar for menu */}
        <div className='sticky-search-top'>
          <div
            className='col-12  d-flex align-items-center position-relative p-0 mt-5 mx-0 mb-0 py-3'
            style={{height: '65px'}}
          >
            <i className='bi bi-search fs-3 position-absolute ms-4'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>{' '}
            <input
              type='text'
              onChange={(e) => {
                  setSearchText(e.target.value)
                }}
              className='form-control form-control-solid w-100 ps-12 h-100 search-input'
              placeholder={intl.formatMessage({id: 'olo.search_items'})}
            />
          </div>
        </div>
        {filteredData.length > 0 && <>
          <div
            className={`sticky-cat-top ${filteredData.length == 0 ? 'd-none d-md-block' : ''}`}
            style={{ height: '57px' }}
          >
            <div className='row'>
              <nav className='stickyNav col-12 col-md-12' id='stickyNav'>
                <div>
                  <button
                    type='button'
                    id='scroll-left'
                    className={`${!scrollCatLeft ? 'd-none' : ''}`}
                    onClick={handleSchrollLeft}
                  >
                    &lt;
                  </button>
                  <button
                    type='button'
                    className={`${!scrollCatRight ? 'd-none' : ''}`}
                    id='scroll-right'
                    onClick={handleSchrollRight}
                  >
                    &gt;
                  </button>
                </div>
                {filteredData.map((key: menuCategory, index: number) => {
                  const capitalizedCategoryName = capitalizeWords(key.name)
                  return (
                    <div
                      id={`nav-item-${index}`}
                      key={index}
                      className={`navItemContainer px-5 pt-1`}
                    >
                      <a
                        className={`nav-header-link px-0 pb-2 ${activeMenu == `section-${index}`
                            ? 'activeNavItem'
                            : activeMenu == '' && index == 0
                              ? 'activeNavItem'
                              : ''
                          }`}
                        onClick={handleClickScroll}
                        type='button'
                        data-catname={`${key?.name}`}
                        data-id={`section-${index}`}
                      />
                      <a
                        className={`nav-header-link px-0 pb-2 ${activeMenu == `section-${index}`
                            ? 'activeNavItem'
                            : activeMenu == '' && index == 0
                              ? 'activeNavItem'
                              : ''
                          }`}
                        onClick={handleClickScroll}
                        type='button'
                        data-catname={`${key?.name}`}
                        data-id={`section-${index}`}
                      >
                        {capitalizedCategoryName}
                      </a>
                    </div>
                  )
                })}
              </nav>
            </div>
          </div>
          <div id='sticky-separator-dashed' className='separator separator-dashed'></div>
        </>
        }
        {/*end of navbar for menu */}
        <div className='flex-lg-row-fluid justify-content-center align-items-center'>
          <div className='h-100vh'>
            {menuCategoriesIsloading == false ? (
              filteredData.length > 0 ? (
                filteredData.map((key: menuCategory, index: number) => {
                  const filteredItemsData = key._embedded.items.filter((item) => {
                    const itemName = getMenuItemName(item)
                    return itemName.toLowerCase().match(searchText.toLowerCase()) != null
                  })
                  if (filteredItemsData.length > 0) {
                    return (
                      <div className={`section-start py-2 `} id={`section-${index}`} key={index}>
                        <MenuCategoryHeader
                          catId={key.id}
                          title={key.name}
                          totalItems={filteredItemsData.length}
                          categorySectionId={`section-${index}`}
                        />
                        <div className={`tns tns-default tns-initiazlied`}>
                          <TinySlider settings={sliderSettings}>
                            {filteredItemsData.length > 0 &&
                              filteredItemsData
                                .slice(0, sliderDisplayItemCount)
                                .map((item: itemObject, innerIndex: number) => {
                                  return (
                                    <MenuItemCard
                                      key={innerIndex}
                                      itemName={getMenuItemName(item)}
                                      itemDescription={item.description}
                                      itemPrice={item.price_per_unit}
                                      itemImage={item.image}
                                      itemId={item.id}
                                      xtraClassName={'itemslider'}
                                      categoryName={key.name}
                                      categoryId={key.id}
                                      from='category'
                                      categorySectionId={`section-${index}`}
                                    />
                                  )
                                })}
                          </TinySlider>
                        </div>
                      </div>
                    )
                  } else {
                    return <></>
                  }
                })
              ) : (
                <div className='row py-10 text-center'>
                  <div className='text-primary mx-auto fs-4 text-danger' role='status'>
                    <FormattedMessage id='olo.no_item_found' defaultMessage='No Item Found' />
                  </div>
                </div>
              )
            ) : (
              <SkeletonLoader />
            )}
          </div>
        </div>
        {menuCategoriesIsloading == false && disclaimer != null && (
          <div>
            <div>
              {renderDisclaimer(disclaimer, verboseDisclaimer)}
              {30 < (disclaimer?.split(' ')?.length || 0) && (
                <span
                  className='cursor-pointer'
                  onClick={() => setVerboseDisclaimer((prevState) => !prevState)}
                >
                  {verboseDisclaimer
                    ? `  ${intl.formatMessage({id: 'olo.show_less'})}`
                    : `  ${intl.formatMessage({id: 'olo.show_more'})}`}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }

  return <LoadingPage />
}
